import React from "react";
import Layout from "../../components/layout";
import DataAndAnalytics from "../../components/views/solutions/data-and-analytics";

const DataAndAnalyticsPage = () => {
  return (
    <Layout>
      <DataAndAnalytics />
    </Layout>
  );
};

export default DataAndAnalyticsPage;
